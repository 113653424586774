import Image from "next/image";

type Props = {
    count: number;
};

const Star = ({ count }: Props) => {
    return (
        <div className="flex items-center justify-center gap-[2px]">
            {/* Array.form used instead of Array.fill to avoid the same object reference */}

            {Array.from({ length: count }, (_, i) => (
                <div
                    className="lg:block hidden h-[16px] w-[16px] lg:h-[20px] lg:w-[20px]"
                    key={i}
                >
                    <Image
                        key={i}
                        src="/review/star.svg"
                        alt="Star icon"
                        height={20}
                        width={20}
                        className="w-auto h-auto"
                    />
                </div>
            ))}
            {Array.from({ length: 5 - count }, (_, i) => (
                <div
                    className="lg:block hidden h-[16px] w-[16px] lg:h-[20px] lg:w-[20px]"
                    key={i}
                >
                    <Image
                        key={i}
                        src="/review/star-disable.svg"
                        alt="Star icon"
                        height={20}
                        width={20}
                        className="h-auto w-auto"
                    />
                </div>
            ))}
        </div>
    );
};

export default Star;
