import Star from './Star'

const Reviews = () => {
	const reviewData = [
		{
			id: 1,
			date: 'June 8, 2023',
			stars: 5,
			img: 'SO',
			dp_color: 'bg-blue-700',
			name: 'Stephen Otieno',
			review: `I recently had the opportunity to use the "Rent a Car. Rent 80" app, and I must say it exceeded my expectations. The user-friendly interface made booking a car rental a breeze, and I was impressed by the extensive range of vehicle options available. The app's seamless navigation and efficient reservation process ensured a hassle-free experience from start to finish. Additionally, the customer support team was responsive and provided excellent assistance when needed. I highly recommend it.`,
		},
		{
			id: 2,
			date: 'June 21, 2023',
			stars: 5,
			img: 'TM',
			dp_color: 'bg-pink-700',
			name: 'Tricia Masonde',
			review: `I am surprised the app is also available in my country Zambia. I would like to try it out and actually rent a car. The interface the app has is easy to use and I would recommend any one that wants to rent a car while abroad. Try it out..download the app onto your phone using play store. Search for rental car80.`,
		},
		{
			id: 3,
			date: 'June 29, 2023',
			stars: 4,
			img: 'JD',
			dp_color: 'bg-green-700',
			name: 'Jamoh Deroh',
			review: `I got this app on play store then I decided to check it out and this is where I got the best Rental Cars at affordable rates and so I highly recommend this app.`,
		},
	]
	return (
		<div className='lg:max-h-[495px] max-h-[50vh] overflow-y-scroll rounded-[12px] border-[0.7px] border-[#D8DFEE] py-[6px] px-3 lg:px-[130px] border-shadow '>
			{reviewData.map((review) => {
				return (
					<div
						key={review.id}
						className='py-5 border-b border-dashed'>
						<div className='flex items-center justify-start gap-3 '>
							<div
								className={`rounded-full ${review.dp_color} w-12 h-12 text-lg text-white font-extrabold p-2 aspect-square flex justify-center items-center`}>
								{review.img}
							</div>
							<div className='space-y-[4px]'>
								<p className='text-sm font-semibold lg:text-xl text-my-dark-blue'>
									{review.name}
								</p>
								<div className='flex items-center justify-start gap-1'>
									<Star count={review.stars}/>
									<p className='text-my-light-blue text-[10px] lg:text-sm'>
										{review.date}
									</p>
								</div>
							</div>
						</div>
						<p className='text-[#38478F] text-xs mt-[10px] leading-[22px]'>
							{review.review}
						</p>
					</div>
				)
			})}
		</div>
	)
}

export default Reviews
