import Image from 'next/image'

const ReviewGoogle = () => {
	return (
		<div className='rounded-[12px] border-[0.7px] border-[#D8DFEE] py-[13px] px-3 lg:px-5 customer-review-border-shadow flex flex-row lg:flex-col items-center justify-start w-full lg:max-w-[394px]'>
			<div className='flex items-center justify-start gap-[18px] pr-[17px] border-r lg:border-b lg:border-r-0 lg:py-[25px] border-dashed lg:w-full'>
				<p className='font-extrabold text-3xl'>
					3.6<span className='text-xl font-normal'>/</span>
					<span className='font-normal text-sm'>5</span>
				</p>
				<div className='space-y-[2px]'>
					<div className='flex items-center justify-start gap-[2px]'>
						<Image
							src='/review/star.svg'
							height={20}
							width={20}
							alt='Star icon'
						/>
						<Image
							src='/review/star.svg'
							height={20}
							width={20}
							alt='Star icon'
						/>
						<Image
							src='/review/star.svg'
							height={20}
							width={20}
							alt='Star icon'
						/>
						<Image
							src='/review/star-qtr.svg'
							height={20}
							width={20}
							alt='Star icon'
						/>
						<Image
							src='/review/star-disable.svg'
							height={20}
							width={20}
							alt='Star icon'
						/>
					</div>
					<p className='font-normal text-xs text-my-light-blue'>
						From 679 ratings
					</p>
				</div>
			</div>
			<div className='hidden lg:flex flex-col border-b py-[25px] border-dashed w-full space-y-[26px]'>
				<div className='w-full flex items-center justify-start gap-[18px]'>
					<p className='font-medium text-xl text-[#38478F]'>5</p>
					<div className='bg-[#F1F2F7] h-2 w-full rounded-full'>
						<div className='h-2  rounded-full bg-[#009F36] w-[57.0093%]'></div>
					</div>
				</div>
				<div className='w-full flex items-center justify-start gap-[18px]'>
					<p className='font-medium text-xl text-[#38478F]'>4</p>
					<div className='bg-[#F1F2F7] h-2 w-full rounded-full'>
						<div className='h-2  rounded-full bg-[#009F36] w-[9.7134%;]'></div>
					</div>
				</div>
				<div className='w-full flex items-center justify-start gap-[18px]'>
					<p className='font-medium text-xl text-[#38478F]'>3</p>
					<div className='bg-[#F1F2F7] h-2 w-full rounded-full'>
						<div className='h-2  rounded-full bg-[#009F36] w-[22.2181%;]'></div>
					</div>
				</div>
				<div className='w-full flex items-center justify-start gap-[18px]'>
					<p className='font-medium text-xl text-[#38478F]'>2</p>
					<div className='bg-[#F1F2F7] h-2 w-full rounded-full'>
						<div className='h-2  rounded-full bg-[#009F36] w-[7.63241%;]'></div>
					</div>
				</div>
				<div className='w-full flex items-center justify-start gap-[18px]'>
					<p className='font-medium text-xl text-[#38478F]'>1</p>
					<div className='bg-[#F1F2F7] h-2 w-full rounded-full'>
						<div className='h-2  rounded-full bg-[#009F36] w-[3.42679%;]'></div>
					</div>
				</div>
			</div>
			<Image
				src='/review/google.svg'
				width={96}
				height={34}
				alt='Google svg'
				className='ml-[17px] lg:ml-0 lg:mt-auto'
			/>
		</div>
	)
}

export default ReviewGoogle
