import ReviewGoogle from './ReviewGoogle'
import Reviews from './Reviews'

const Review = () => {
	return (
		<section
			id='customer-reviews'
			className='bg-white px-[13px] py-[22px] lg:py-[60px] my-[40px]'>
			<div className='max-w-7xl mx-auto'>
				<h2 className='font-extrabold text-[18px] lg:text-[30px] text-my-dark-blue mb-[22px] lg:mb-[40px]'>
					Customer Reviews
				</h2>
				<div className='gap-y-[22px] gap-x-[24px] flex flex-col lg:flex-row'>
					<ReviewGoogle />
					<Reviews />
				</div>
			</div>
		</section>
	)
}

export default Review
